.footer {
  position: relative;
  padding: 50px 0;
  background-color: #21303F;

  @include media(1024px) {
    padding: 38px 0;
  }

  @include media(1024px) {
    padding: 38px 0;
  }

  @include media(450px) {
    padding: 10px 0;
  }

  &__logo {
    width: 405px;
    height: 66px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url(../img/logo-white.png);

    @include media(1024px) {
      width: 255px;
      height: 42px;
    }

    @include media(600px) {
      width: 175px;
      background-position: center left;
      background-size: contain;
    }
  }

  &__social {
    align-self: flex-end;
    fill: rgba(#fff, 0.6);

    @include media(1024px) {
      order: 0;
    }
  }

  &__bm {
    display: inline-block;
    width: 85px;
    height: 40px;
    margin-right: 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url(../img/BM-logo.png);

    @include media(1024px) {
      margin-right: 10px;
    }

    @include media(600px) {
      margin-right: 5px;
      width: 75px;
      height: 35px;
    }

    @include media(450px) {
      margin-right: 0;
    }
  }

  &__copyright {
    display: inline-block;

    @include media(450px) {
      display: none;
    }
  }

  &__text {
    display: block;
    font-size: 18px;
    font-weight: 200;
    line-height: 24px;
    color: #fff;

    @include media(1024px) {
      font-size: 12px;
      line-height: 20px;
    }

    @include media(600px) {
      font-size: 11px;
    }
  }

  &__link {
    color: inherit;
    text-decoration: underline;

    &:hover {
      color: inherit;
    }
  }
}