$main_color: #1D2A36;

$text_main_color: #565656;
$text_second_color: #3f3f3f;

$button_color_first: #E31E24;
$button_color_first_hover: #F03A40;
$button_color_second: #7166f1;
$button_color_second_hover: #524ba8;

$background_color: #F5F5F5;

$content_limit: 1660px;